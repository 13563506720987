const colors = {
  primary: {
    main: '#2ee577',
    hover: '#24b25d',
    active: '#1a7f42',
  },
  secondary: {
    main: '#ee1c49',
    active: '',
    hover: '',
  },
  tertiary: {
    main: '#8235DA',
    active: '',
    hover: 'rgba(130, 53, 218, 0.4)',
  },
  quartenary: {
    main: '#BA8EEC',
    active: '',
    hover: '#5D15AE',
  },
  neutral: {
    black: '#000000',
    filter: 'rgba(0, 0, 0, 0.6)',
    modal: 'rgba(0, 0, 0, 0.8)',
    white: '#ffffff',
  },
  grayscale: {
    dark: {
      title: '#ffffff',
      paragraph: '#ffffff',
      info: 'rgba(255, 255, 255, 0.6)',
      line: 'rgba(255, 255, 255, 0.2)',
    },
    light: {
      title: '#000000',
      paragraph: 'rgba(0, 0, 0, 0.75)',
      info: 'rgba(0, 0, 0, 0.5)',
      line: '',
    },
  },
  background: {
    dark: {
      input: 'rgba(0, 0, 0, 0.5)',
      button: '',
    },
    light: {
      input: '',
      button: '',
    },
    float: {
      solid: '#1d2224',
    },
  },
  contextual: {
    success: '#38cd3c',
    danger: '#ff0202',
    attention: '#fda700',
  },
}

export default colors
