import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: ${theme.spacings.small};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    z-index: ${theme.layers.overlay};

    ${media.greaterThan("medium")`
      display: none;
    `}
  `}
`;

const menuModifiers = {
  open: () => css`
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  `,
};
export const Menu = styled.div`
  ${({ theme, isOpen }) => css`
    background-color: ${theme.colors.neutral.white};
    border-radius: 16px 16px 0px 0px;
    padding: ${theme.spacings.medium};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(100% - ${theme.spacings.xsmall});
    width: 100%;
    transform: translateY(100%);
    transition: all 0.5s ease;
    opacity: 0;
    pointer-events: none;
    z-index: ${theme.layers.alwaysOnTop};

    ${isOpen && menuModifiers.open()};
  `}
`;

export const LogoWrapper = styled.a`
  svg {
    height: 28px;
    width: auto;
  }
`;

export const Space = styled.span`
  flex-basis: 20%;
`;

export const MenuNav = styled.nav`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};
  `}
`;

const languageWrapperModifiers = {
  show: (theme) => css`
    border-right-color: ${theme.colors.secondary.main};
  `,
};

export const LanguageWrapper = styled.div`
  ${({ theme, show }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-right: 1px solid transparent;
    padding-right: ${theme.spacings.xxsmall};
    margin-right: -${theme.spacings.xxsmall};
    height: 110px;

    ${show && languageWrapperModifiers.show(theme)};
  `}
`;

export const MenuLink = styled.a`
  ${({ theme, active }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const LanguageOption = styled.button`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    pointer-events: all;
    position: relative;
    line-height: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  `}
`;

export const LanguageIcon = styled.img`
  ${({ theme }) => css`
    width: 18px;
    margin-right: ${theme.spacings.xxsmall};
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    position: absolute;
    right: ${theme.spacings.xsmall};
    top: ${theme.spacings.medium};
    height: 34px;
    width: 34px;
  `}
`;

export const OpenMenu = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    height: 24px;
    width: 24px;
  `}
`;
