import styled, { css } from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";
import media from "styled-media-query";

const MODIFIER_CONFIG = {
  uppercase: () => css`
    text-transform: uppercase;
  `,
  center: () => css`
    text-align: center;
  `,
  italic: () => css`
    font-style: italic;
  `,
  dark: ({ theme }) => css`
    color: ${theme.grayscale.dark.title};
  `,
  bold: ({ theme }) => css`
    font-weight: bold;
    line-height: 32px;
    font-family: ${theme.font.family};
  `,
};

export const Title = styled.h2`
  ${({ theme, lineHeight = 1, mb }) => css`
    color: ${theme.colors.grayscale.light.theme};
    font-size: ${theme.font.sizes.title};
    font-family: ${theme.font.family};
    line-height: ${lineHeight};
    font-weight: 600;

    ${media.lessThan("large")`
      font-size: ${theme.font.sizes.xxlarge};
      margin-bottom: ${mb || theme.spacings.large};
    `}

    ${media.lessThan("small")`
      font-size: ${theme.font.sizes.xlarge};
    `}

    &:not(:last-child) {
      margin-bottom: ${mb || theme.spacings.medium};
    }
  `}

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export const SubTitle = styled(Title)`
  font-size: 22px;

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export const Paragraph = styled.p`
  ${({ theme, lineHeight = "24px", opacity = 1 }) => css`
    color: ${theme.colors.grayscale.light.paragraph};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family};
    font-weight: 400;
    line-height: ${lineHeight};
    letter-spacing: 0.5px;
    opacity: ${opacity};

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.medium};
    }
  `}

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;
