import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Line = styled.div`
  ${({ theme }) => css`
    height: 245px;
    border-left: 2px solid black;
    position: absolute;
    left: ${theme.spacings.xlarge};
    transform: translateY(-170px);

    ${media.lessThan("medium")`
      height: 111px;
      left: ${theme.spacings.xsmall};
      transform: translateY(-100px);
    `}
  `}
`;

export const ParagraphTitle = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    margin-bottom: ${theme.spacings.xxsmall};
    font-weight: ${theme.font.bold};
  `}
`;
export const ParagraphSecondTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    margin: ${theme.spacings.small} 0 ${theme.spacings.xxsmall};
    line-height: 16px;
    font-weight: ${theme.font.bold};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background: #2ee577;
    padding: ${theme.spacings.xlarge} 128px;
    margin-top: 120px;

    ${media.lessThan("large")`
      padding: ${theme.spacings.large};
    `}

    ${media.lessThan("medium")`
      text-align: center;
      padding: ${theme.spacings.medium};
      margin-top: 90px;
    `}
  `}
`;

export const Wrapper = styled.div`
  display: flex;

  ${media.lessThan("large")`
    flex-wrap: wrap;
  `}

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

export const Group = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} ${theme.spacings.medium} 0;
    align-items: flex-start;
    flex: 0 1 calc(25% - 10px);

    ${media.lessThan("large")`
      margin: 0 24px;
      flex: 1;
    `}
  `}
`;

export const GroupNetworks = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
`;

export const Paragraph = styled.p`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    margin-bottom: ${theme.spacings.medium};
    opacity: 1;
    transition: opacity 250ms;

    a {
      opacity: 1;
      transition: opacity 250ms;

      &:hover {
        opacity: 0.7;
      }
    }
  `};
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    margin-bottom: ${theme.spacings.xsmall};
    text-align: center;
    font-weight: ${theme.font.bold};

    ${media.lessThan("medium")`
      margin-bottom: ${theme.spacings.medium};
    `}
  `}
`;

export const Logo = styled.img`
  width: 100%;
`;

export const IconContainer = styled.div`
  margin: 0 12px;

  path {
    transition: fill 250ms;
  }

  a {
    &:hover {
      path {
        fill: white;
      }
    }
  }
`;

export const ParagraphTitleNetworks = styled.h4`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    margin-bottom: ${theme.spacings.medium};
    text-align: center;
    font-weight: ${theme.font.bold};
  `}
`;

export const ButtonInfo = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `}
`;

export const Linkme = styled.p`
  text-align: center;
  margin-top: 10px;
`;
