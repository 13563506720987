import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.section`
  width: 100%;

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    top: 0;
    left: 0;
    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slider.center img {
    margin: 0 auto;
  }

  .slick-slide {
    display: none;
    float: left;
    outline: none;
    height: 100%;
    min-height: 1px;
    flex: 1 0 auto;
    padding: 0 4px;

    div {
      outline: none;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-track,
  .slick-list {
    display: flex;
  }

  ${({ theme }) => css`
    .slick-track,
    .slick-list {
      display: flex;
    }

    .slick-prev_modal,
    .slick-next_modal {
      position: absolute;
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: unset;
      bottom: 50%;
      z-index: 2;
      transition: all 250ms;
      width: 60px;
      height: 70px;
    }

    .slick-next_modal {
      right: -100px;

      @media (max-width: 1300px) {
        right: -65px;
      }

      @media (max-width: 1150px) {
        right: -80px;
      }

      @media (max-width: 940px) {
        right: -60px;
      }

      @media (max-width: 940px) {
        right: -20px;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: white;
        width: 6px;
        height: 45px;
        transform: rotate(45deg);
        bottom: 0;

        @media (max-width: 768px) {
          width: 4px;
          height: 30px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        background-color: white;
        width: 6px;
        height: 45px;
        transform: rotate(-45deg);
        bottom: 27px;

        @media (max-width: 768px) {
          width: 4px;
          height: 30px;
          bottom: 19px;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .slick-prev_modal {
      left: -100px;

      @media (max-width: 1300px) {
        left: -65px;
      }

      @media (max-width: 1150px) {
        left: -80px;
      }

      @media (max-width: 940px) {
        left: -60px;
      }

      @media (max-width: 940px) {
        left: -20px;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: white;
        width: 6px;
        height: 45px;
        transform: rotate(-45deg);
        bottom: 0;

        @media (max-width: 768px) {
          width: 4px;
          height: 30px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        background-color: white;
        width: 6px;
        height: 45px;
        transform: rotate(45deg);
        bottom: 27px;

        @media (max-width: 768px) {
          width: 4px;
          height: 30px;
          bottom: 19px;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .slick-prev_depositions,
    .slick-next_depositions {
      position: absolute;
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: unset;
      bottom: 75px;
      z-index: 2;
      transition: all 250ms;
      width: 30px;
      height: 30px;

      @media (max-width: 768px) {
        bottom: 40px;
      }
    }

    .slick-next_depositions {
      right: 15%;
      transform: translateX(15%);
      opacity: 1;

      &:before {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 9px;
      }

      &:hover {
        opacity: 0.7;
      }

      @media (max-width: 768px) {
        right: 35%;
      }
    }

    @media (max-width: 768px) {
      .slick-next {
        right: 8%;
        transform: translateX(8%);
      }
    }

    .slick-prev_depositions {
      left: 80%;
      transform: translateX(-80%);
      opacity: 1;

      &:before {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 9px;
      }

      &:hover {
        opacity: 0.7;
      }

      @media (max-width: 768px) {
        left: 40%;
      }
    }

    @media (max-width: 768px) {
      .slick-prev {
        left: 70%;
        transform: translateX(-70%);
      }
    }

    .slick-next.slick-disabled_depositions {
      opacity: 0.4;
      right: 15%;
      bottom: 75px;
      transform: translateX(15%);

      &:before {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 9px;
      }

      @media (max-width: 768px) {
        bottom: 40px;
        right: 35%;
      }
    }

    .slick-prev.slick-disabled_depositions {
      opacity: 0.4;
      left: 80%;
      bottom: 75px;
      transform: translateX(-80%);

      &:before {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: black;
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 9px;
      }

      @media (max-width: 768px) {
        bottom: 40px;
        left: 40%;
      }
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: unset;
      bottom: -50px;
      z-index: 2;
      transition: all 250ms;
      width: 30px;
      height: 30px;
    }

    .slick-next {
      right: 47%;
      transform: translateX(47%);
      opacity: 1;

      &.slick-disabled_modal {
        display: none !important;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: ${theme.colors.secondary.main};
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: ${theme.colors.secondary.main};
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 9px;
      }
    }

    @media (max-width: 768px) {
      .slick-next {
        right: 8%;
        transform: translateX(8%);
      }
    }

    .slick-prev {
      left: 47%;
      transform: translateX(-47%);
      opacity: 1;

      &.slick-disabled_modal {
        display: none !important;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: ${theme.colors.secondary.main};
        width: 2px;
        height: 15px;
        transform: rotate(-45deg);
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: ${theme.colors.secondary.main};
        width: 2px;
        height: 15px;
        transform: rotate(45deg);
        bottom: 9px;
      }
    }

    @media (max-width: 768px) {
      .slick-prev {
        left: 70%;
        transform: translateX(-70%);
      }
    }

    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      opacity: 0.2;
    }
  `}

  ${media.lessThan('medium')`
    padding: 0;
  `}
`

export const ArrowRight = styled.button`
  background-color: purple;
`

export const ArrowLeft = styled(ArrowRight)`
  svg {
    transform: rotate(180deg);
  }
`
